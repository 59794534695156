import React from "react";
import ViewCampaignContent from "../Components/Campaigns/ViewCampaignContent";
import LoginLayout from "../Layouts/LoginLayout";

export default function ViewCampaign({ title }) {
  return (
    <LoginLayout title={title}>
      <ViewCampaignContent />
    </LoginLayout>
  );
}
