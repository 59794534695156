import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import {
  AiOutlinePlus,
  AiOutlineLink,
  AiOutlineDelete,
  AiOutlineFile,
} from "react-icons/ai";
import { BiFilter } from "react-icons/bi";
import { MdOutlinePlayArrow } from "react-icons/md";
import { FaLinkedinIn } from "react-icons/fa";
import RangeSlider from "react-bootstrap-range-slider";
import { useNavigate } from "react-router-dom";
import Loader from "../UI/Loader";
import CampaignSteps from "./CampaignSteps";
import { useSelector } from "react-redux";
import { selectProfile } from "../../features/profile/profileSlice";
import { toast, ToastContainer } from "react-toastify";
import { useQuery } from "react-query";
import { getRecord, createRecord } from "../../Config/apiFunctions";
import routes from "../../Config/routes/api";

const {
  campaignRoute,
  triggerCampaignRoute,
  campaignLinkedinAccountsRoute,
  campaignSequenceRoute,
  linkedinAccountsRoute,
} = routes;

export default function CreateCampaignContent() {
  const [validated, setValidated] = useState(false);
  const [isError, setIsError] = useState(false);
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [campaignName, setCampaignName] = useState('');

  const [steps, setSteps] = useState([]);
  const [campType, setCampType] = useState('0');
  const [campName, setCampName] = useState("");
  const [premiumAccountOnly, setPremiumAccountOnly] = useState(false);
  const [linkTracking, setLinkTracking] = useState(false);
  const [emailOnly, setEmailOnly] = useState(false);
  const [moveProspects, setMoveProspects] = useState(false);
  const [includeProspects, setIncludeProspects] = useState(false);
  const [linkCookie, setLinkCookie] = useState('');
  const [campaignStatus, setCampaignStatus] = useState('0');

  const [activeSearch, setActiveSearch] = useState(1);
  const [searchCount, setSearchCount] = useState(1000);
  const [searchItems, setSearchItems] = useState([]);
  const [query, setQuery] = useState('');
  const [filter, setFilter] = useState({
    conn1: false,
    conn2: false,
    conn3: false,
    location: "",
    currComp: "",
  });

  const navigate = useNavigate();

  const ref = useRef(null);

  const user = useSelector(selectProfile);
  const [linkedinAccounts, setLinkedinAccounts] = useState([]);
  const [selectedLinkedinAccount, setSelectedLinkedinAccount] = useState({});

  /* const { data: linkedinAccountsList } = useQuery(
    ["linkedinAccounts", user],
    () =>
      getRecord(
        `${linkedinAccountsRoute}?connected=true&ready_for_use=true`,
        user
      ),
    { enabled: !!user.token }
  ); */

  /* useEffect(() => {
    if (!linkedinAccountsList || !linkedinAccountsList.length) return;
    setSelectedLinkedinAccount(linkedinAccountsList[0].id);
    setLinkedinAccounts(linkedinAccountsList);
  }, [linkedinAccountsList]); */

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    console.log("form", form);
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      event.preventDefault();
      event.stopPropagation();
      setValidated(false);
      setShow(false);

      //Add the search item
      if (activeSearch < 6) {
        const newItems = searchItems.concat({ type: activeSearch, query, filter: searchCount });
        setSearchItems(newItems);
      } else {
        //Make a string to display for selected filter values
        let filterText = "";
        if (filter.conn1) {
          filterText = filterText + "1st,";
        }
        if (filter.conn2) {
          filterText = filterText + "2nd,";
        }
        if (filter.conn3) {
          filterText = filterText + "3rd,";
        }
        filterText += filter.location;
        filterText += filter.currComp;
        const newItems = searchItems.concat({ type: activeSearch, query, filter: filterText });
        setSearchItems(newItems);
      }

      setQuery("");
      setSearchCount(1000);
      setFilter({
        conn1: false,
        conn2: false,
        conn3: false,
        location: "",
        currComp: "",
      });
    }
  };

  const handleShow = () => setShow(!show);

  const handleCancel = () => navigate(-1);

  const saveCampaign = async (campStatus = '0') => {
    setIsLoading(true);
    let isValid = true;

    if (!campaignName) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
      setIsError(true);
      isValid = false;
      setIsLoading(false);
    }
    if (searchItems.length === 0) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
      setIsError(true);
      isValid = false;
      setIsLoading(false);
    }

    if (!isValid) return;

    const steps_data = [];

    steps.map(async (item, idx) => {
      if (item.key === "send_connection_request") {
        steps_data.push({
          wait_pre_days: item.waitDays,
          wait_pre_hours: item.waitHours,
          with_message: (item.msg != '') ? '1' : '0',
          message: item.msg,
          type: "0",
        });
      } else if (item.key === "send_message") {
        steps_data.push({
          wait_pre_days: item.waitDays,
          wait_pre_hours: item.waitHours,
          with_message: '1',
          message: item.msg,
          type: "1",
        });
      } else if (item.key === "send_inmail") {
        steps_data.push({
          wait_pre_days: item.waitDays,
          wait_pre_hours: item.waitHours,
          with_message: '1',
          allow_inmailcredits: (item.allowCredits)? '1' : '0',
          subject: item.subject,
          message: item.msg,
          type: "2",
        });
      } else if (item.key === "peform_actions") {
        steps_data.push({
          wait_pre_days: item.waitDays,
          wait_pre_hours: item.waitHours,
          auto_view_profile: (item.viewProfile) ? '1' : '0',
          auto_follow_profile: (item.follow) ? '1' : '0',
          like_posts: (item.likes) ? '1' : '0',
          auto_endorse: (item.endorse) ? '1' : '0',
          type: "3",
        });
      } else if (item.key === "enrich_profile") {
        steps_data.push({
          wait_pre_days: item.waitDays,
          wait_pre_hours: item.waitHours,
          type: "6",
        });
      }
    });

    let values = {
      name: campaignName,
      type: campType,
      premium_account_only: premiumAccountOnly === false ? '0' : '1',
      link_tracking: linkTracking === false ? '0' : '1',
      email_only: emailOnly === false ? '0' : '1',
      move_prospects: moveProspects === false ? '0' : '1',
      include_prospects: includeProspects === false ? '0' : '1',
      searches: searchItems,
      steps: steps_data,
      cookie: linkCookie,
      status: campStatus
    };

    const resp = await createRecord({
      values,
      user,
      url: campaignRoute,
    });

    if (resp) {
      setCampaignStatus(resp.data.status);
      toast.success('Campaign added successfully');
      navigate('/campaigns');

      setIsLoading(false);
    } else {
      toast.error('Something went wrong');
      setIsLoading(false);
    }

    /* let campainId = resp.data.id;

    steps.map(async (item, idx) => {
      if (item.key === "peform_actions") {
        const payloads = [];

        if (item.likes) {
          payloads.push({
            campaign: campainId,
            delay_in_days: item.waitDays,
            delay_in_hours: item.waitHours,
            step: "like_3_posts",
          });
        }

        if (item.follow) {
          payloads.push({
            campaign: campainId,
            delay_in_days: item.waitDays,
            delay_in_hours: item.waitHours,
            step: "follow",
          });
        }

        if (item.endorse) {
          payloads.push({
            campaign: campainId,
            delay_in_days: item.waitDays,
            delay_in_hours: item.waitHours,
            step: "endorse_top_5_skills",
          });
        }

        let first = 0;
        const newPaylods = payloads.map((payload, payloadIdx) => {
          const newPayload = {
            ...payload,
            order: idx + payloadIdx,
          };

          if (first) {
            newPayload.delay_in_days = 0;
            newPayload.delay_in_hours = 0;
          }

          if (!first) {
            first = 1;
          }

          return newPayload;
        });

        /* await newPaylods.map(async (payload) => {
          await createRecord({
            user,
            values: payload,
            url: `${campaignSequenceRoute}`,
          });
        });

        return;
      }

      const payload = {
        campaign: campainId,
        delay_in_days: item.waitDays,
        delay_in_hours: item.waitHours,
        step: item.key,
        order: idx,
      };

      if (item.key === "send_connection_request") {
        payload.note = `${item.msg}`;
      } else if (item.key === "send_message") {
        payload.message = `${item.msg}`;
      } else if (item.key === "send_inmail") {
        payload.inmail_subject = item.subject;
        payload.inmail_message = item.msg;
      } else if (item.key === "send_email") {
        payload.google_account = item.google_account;
        payload.smtp_account = item.smtp_account;
        payload.from_email = item.from_email;
        payload.email_subject = item.subject;
        payload.email_message = item.msg;
      }

      await createRecord({
        user,
        values: payload,
        url: `${campaignSequenceRoute}`,
      });
    });

    await createRecord({
      user,
      values: {
        linkedin_account: selectedLinkedinAccount,
        campaign: campainId,
      },
      url: campaignLinkedinAccountsRoute,
    });

    await createRecord({
      user,
      values: {},
      url: `${triggerCampaignRoute}${campainId}/`,
    }); */

    /* navigate(-1); */
  };

  const handleConnect = () => {
    const connectBtn = document.getElementById("connectbtn");

    if (connectBtn) {
      connectBtn.click();
    }
  };

  const handleDisconnect = () => {
    const hiddenInput = document.getElementById("lnc_secure");

    if (hiddenInput) {
      hiddenInput.value = '';
      setLinkCookie('');

      const event = new Event('change', { bubbles: true });
      hiddenInput.dispatchEvent(event);
    }
  };

  const handleLinkCookieChange = (e) => {
    if (e.target.value != '') {
      setLinkCookie(e.target.value);
    }
  };

  const handleURLPlaceholder = () => {
    switch (activeSearch) {
      case 1:
        return "Enter LinkedIn or Sales Navigator search link";
      case 2:
        return "Enter LinkedIn link to post";
      case 3:
        return "Enter LinkedIn event link";
      case 4:
        return "Enter LinkedIn Sales Navigator List link";
      case 5:
        return "Enter LinkedIn Recruiter Project link";
      default:
        break;
    }
  };

  const handleCountLimit = (e) => {
    if (e.target.value > 2500) {
      e.target.value = 2500;
    }
    if (activeSearch === 3) {
      if (e.target.value > 1000) {
        e.target.value = 1000;
      }
    }
    if (e.target.value < 1) {
      e.target.value = 1;
    }
  };

  const handleEventSearch = () => {
    setActiveSearch(3);
    if (searchCount > 1000) {
      setSearchCount(1000);
    }
  };

  function removeSearchItem(index) {
    let data = [...searchItems];
    data.splice(index, 1);
    setSearchItems(data);
  }

  console.log(selectedLinkedinAccount);

  const renderButton = () => {
    return linkCookie === '' || linkCookie === null ? (
      <>
        <Button variant='secondary' onClick={() => { saveCampaign('0'); }}>Save as Draft</Button>
        <div>
          <Button variant='secondary' className='me-2' onClick={handleCancel}>Cancel</Button>
          <Button variant='primary' onClick={() => { handleConnect(); }}>
            <FaLinkedinIn className="d-none d-sm-inline-flex" size={14} /> Connect Session
          </Button>
        </div>
      </>
    ) : (
      <>
        <Button variant='secondary' onClick={() => { saveCampaign('0'); }}>Save as Draft</Button>
        <div>
          <Button variant='secondary' className='me-2' onClick={handleCancel}>Cancel</Button>
          <Button variant='primary' onClick={() => { handleDisconnect(); }}>
            <FaLinkedinIn className="me-1" size={14} /> Disconnect Session
          </Button>
          <Button variant='primary' className="ms-2" type='submit' onClick={() => { saveCampaign('1'); }}>
            Start Outreach <MdOutlinePlayArrow size={20} />
          </Button>
        </div>
      </>
    );
  };

  useEffect(() => {
    const hiddenInput = document.getElementById('lnc_secure');
    if (hiddenInput) {
      hiddenInput.addEventListener('change', handleLinkCookieChange);
    }
  }, []);

  return (
    <div className="create-campaign-content main-bg p-4 custom-scrollbar">
      {
        isLoading ?
          <Loader /> :
          <>
            <div className="row g-0" ref={ref}>
              <div className="col-md-6">
                <div className="box-card p-3 me-2">
                  <div className="mb-3">
                    <Form.Label>Campaign Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="campaign-name"
                      value={campaignName}
                      onChange={(e) => {
                        setCampaignName(e.target.value);
                        console.log(e.target.value);
                        setIsError(false);
                      }}
                      placeholder="Enter Campaign Name"
                    />
                    {!campaignName && isError ? (
                      <div className="text-danger">
                        {"Please enter campaign name"}
                      </div>
                    ) : null}
                  </div>
                  {/* <div className="mb-3">
              <Form.Label>Linkedin Profile</Form.Label>
              <Form.Select
                value={selectedLinkedinAccount}
                onChange={(e) => {
                  setSelectedLinkedinAccount(e.target.value);
                }}
              >
                {linkedinAccounts.map((linkedinAccount) => {
                  return (
                    <option value={linkedinAccount.id}>
                      {linkedinAccount.name || linkedinAccount.username}
                    </option>
                  );
                })}
              </Form.Select>
            </div> */}
                  <div className="mb-3">
                    <Form.Label>Campaign Type</Form.Label>
                    <Form.Select
                      as='select'
                      value={campType}
                      onChange={(e) => setCampType(e.target.value)}
                    >
                      <option value={'0'}>Outreach Campaign</option>
                      <option value={'1'}>Engagement Campaign</option>
                    </Form.Select>
                  </div>
                  <div className="mb-3">
                    <Form.Switch
                      id="camp-switch1"
                      label="LinkedIn Premium accounts only"
                      checked={premiumAccountOnly}
                      onChange={(e) => setPremiumAccountOnly(e.target.checked)}
                    />
                  </div>
                  <div className="mb-3">
                    <Form.Switch
                      id="camp-switch2"
                      label="Enable link tracking"
                      checked={linkTracking}
                      onChange={(e) => setLinkTracking(e.target.checked)}
                    />
                  </div>
                  <div className="mb-3">
                    <Form.Switch
                      id="camp-switch3"
                      label="Email only campaign"
                      checked={emailOnly}
                      onChange={(e) => setEmailOnly(e.target.checked)}
                    />
                  </div>
                  <div className="mb-3">
                    <Form.Switch
                      id="camp-switch4"
                      label="Move prospects from other campaigns if they are found"
                      checked={moveProspects}
                      onChange={(e) => setMoveProspects(e.target.checked)}
                    />
                  </div>
                  <div className="mb-3">
                    <Form.Switch
                      id="camp-switch5"
                      label="Include prospects I've contacted on LinkedIn before"
                      checked={includeProspects}
                      onChange={(e) => setIncludeProspects(e.target.checked)}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="searches box-card p-3 ms-2 custom-scrollbar">
                  <p>Searches</p>
                  {searchItems.length === 0 ? (
                    <>
                      {isError ? (
                        <div className="text-danger">
                          {"Please add atleast one search"}
                        </div>
                      ) : null}
                    </>
                  ) : (
                    searchItems.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="search-item d-flex justify-content-between p-2 mb-3"
                        >
                          <div className="d-flex align-items-center">
                            <span>
                              <AiOutlineLink size={18} />
                            </span>
                            <p className="query">{item.query}</p>
                            <span>
                              <BiFilter size={18} />
                            </span>
                            <p className="filter">{item.filter}</p>
                          </div>
                          <Button
                            variant="red"
                            onClick={() => removeSearchItem(index)}
                          >
                            <AiOutlineDelete size={20} />
                          </Button>
                        </div>
                      );
                    })
                  )}
                  <Button variant="primary" onClick={handleShow}>
                    Add Search <AiOutlinePlus size={20} />
                  </Button>
                </div>
              </div>
            </div>

            {/***** Add Search Popup *****/}

            <Modal
              show={show}
              onHide={handleShow}
              size="lg"
              centered
              className="max-heighted-modal add-search-modal"
            >
              <Modal.Header closeButton>
                <Modal.Title>Add Search</Modal.Title>
              </Modal.Header>
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Modal.Body>
                  <div className="mb-4">
                    <p className="mb-1">Add From:</p>
                    <Button
                      variant="tab"
                      onClick={() => setActiveSearch(1)}
                      className={activeSearch === 1 ? "active" : ""}
                    >
                      Search URL
                    </Button>
                    {/* <Button 
                            variant='tab' 
                            onClick={() => setActiveSearch(2)}
                            className={activeSearch === 2 ? 'active' : ''}
                            >
                            Post URL
                        </Button>
                        <Button 
                            variant='tab'
                            onClick={handleEventSearch}
                            className={activeSearch === 3 ? 'active' : ''}
                            >
                            Event
                        </Button> */}
                    <Button
                      variant="tab"
                      onClick={() => setActiveSearch(4)}
                      className={activeSearch === 4 ? "active" : ""}
                    >
                      Navigator List URL
                    </Button>
                    {/* <Button 
                            variant='tab'
                            onClick={() => setActiveSearch(5)}
                            className={activeSearch === 5 ? 'active' : ''}
                            >
                            Recruiter Project URL
                        </Button>
                        <Button 
                            variant='tab' 
                            onClick={() => setActiveSearch(6)}
                            className={activeSearch === 6 ? 'active' : ''}
                            >
                            LinkedIn Search
                        </Button> */}
                  </div>
                  {activeSearch > 0 && activeSearch < 6 && (
                    <div>
                      <div className="mb-3">
                        <Form.Control
                          type="text"
                          id="search-url"
                          placeholder={handleURLPlaceholder()}
                          onChange={(e) => setQuery(e.target.value)}
                          required
                          value={query}
                        />
                      </div>
                      <div className="row g-0">
                        <p className="mb-0">No. of Searches:</p>
                        <div className="col-10">
                          <RangeSlider
                            tooltip="off"
                            variant="primary"
                            min={1}
                            max={activeSearch === 3 ? 1000 : 2500}
                            value={searchCount}
                            onChange={(e) => setSearchCount(e.target.value)}
                          />
                        </div>
                        <div className="col-2 ps-3">
                          <Form.Control
                            type="number"
                            id="search-count"
                            min={1}
                            max={activeSearch === 3 ? 1000 : 2500}
                            value={searchCount}
                            onChange={(e) => setSearchCount(e.target.value)}
                            onInput={handleCountLimit}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {activeSearch > 5 && (
                    <div className="mb-3">
                      <Form.Control
                        type="text"
                        id="search-query"
                        placeholder="Enter search query"
                        onChange={(e) => setQuery(e.target.value)}
                        required
                        value={query}
                      />
                    </div>
                  )}
                  {activeSearch === 6 && (
                    <div>
                      <div className="mb-3">
                        <Form.Label>Connections:</Form.Label>
                        <div className="d-flex flex-wrap">
                          <Form.Check
                            type="checkbox"
                            name="1st-conn"
                            label="1st"
                            onChange={(e) =>
                              e.target.checked
                                ? setFilter({ ...filter, conn1: true })
                                : setFilter({ ...filter, conn1: false })
                            }
                          />
                          <Form.Check
                            type="checkbox"
                            name="2nd-conn"
                            label="2nd"
                            onChange={(e) =>
                              e.target.checked
                                ? setFilter({ ...filter, conn2: true })
                                : setFilter({ ...filter, conn2: false })
                            }
                          />
                          <Form.Check
                            type="checkbox"
                            name="3rd-conn"
                            label="3rd"
                            onChange={(e) =>
                              e.target.checked
                                ? setFilter({ ...filter, conn3: true })
                                : setFilter({ ...filter, conn3: false })
                            }
                          />
                        </div>
                      </div>
                      <div className="mb-3">
                        <Form.Label>Location:</Form.Label>
                        <Form.Control
                          type="text"
                          id="location"
                          placeholder="Enter location"
                          onChange={(e) =>
                            setFilter({ ...filter, location: e.target.value })
                          }
                        />
                      </div>
                      <div className="mb-3">
                        <Form.Label>Current Company:</Form.Label>
                        <Form.Control
                          type="text"
                          id="current-company"
                          placeholder="Enter current company"
                          onChange={(e) =>
                            setFilter({ ...filter, currComp: e.target.value })
                          }
                        />
                      </div>
                    </div>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <Button type="submit" variant="primary">
                    Add Search
                  </Button>
                </Modal.Footer>
              </Form>
            </Modal>

            {/***** Campaign Steps *****/}

            <CampaignSteps
              onChange={(steps) => {
                console.log(steps);
                setSteps(steps);
              }}
              prefetchedSteps=""
            />

            <div className="d-flex justify-content-between mt-5">
              {(campaignStatus == '0') ? renderButton() : <Button variant='secondary' onClick={handleCancel}>Cancel</Button>}
            </div>
          </>
      }
      <Form.Control
        type='hidden'
        id='lnc_secure'
        value={linkCookie}
        onChange={handleLinkCookieChange}
      />
      <ToastContainer theme="colored" />
    </div>
  );
}
